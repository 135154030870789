.botiga-calendar .MuiOutlinedInput-adornedEnd{
    padding-right: 4px;
}

.MuiPickersDay-current{
    color: #179F57 !important;
}
.MuiPickersDay-daySelected{
    color: white !important;
    background-color:#179F57 !important; 
}

.MuiTextField-root.botiga-calendar-withbtn{
    width: 0px !important;
}