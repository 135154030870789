.coupon-list-style{
    width: 320px;
    height: 827px;
    display: flex;
    flex-direction: column;
}

.coupon-header-item{
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.coupon-header-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
}
.coupon-header-btn.MuiButton-text{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #179F57;
}


.coupon-list-body{
    overflow-y: auto;
}

.coupon-item{
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid  #DCDCDC;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
}
.coupon-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    display: flex;
    flex: 1;
    word-break: break-word;
}
.coupon-info{
  display: flex
}
.coupon-badge{
    display: flex;
    justify-content: center;
    padding: 2px 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    background: #D90D0D;
    border-radius: 3px;
}
