.banner-list-style{
    position: relative;
    width: 100%;
    height: 827px;
    display: flex;
    flex-direction: column;
    overflow-y:hidden;
}
.banner-header-item{
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid  #DCDCDC;
}
.banner-header-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
}


.banner-body{
    display: flex;
    flex-direction: column;
    width: 530px;
    padding-top: 40px;
    padding-left: 120px;
}
.banner-row{
    display: flex;
    margin-bottom: 30px;
    width: 100%;
    justify-content: center;
}
.banner_info{
    display: flex;
    height: 68px;
    width: 100%;
    background: #F7F7F7;
    border-radius: 4px;
    justify-content: space-around;
    align-items: center;
}
.banner_info .block_section{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
}
.banner_info .primary{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #000000;
    opacity: 0.4;
}
.banner_info .secondary{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.2px;
    color: #000000;
}



.banner-upload-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 320px;
    height: 160px;
    border: 1px dashed rgba(18, 23, 21, 0.25);
    box-sizing: border-box;
    border-radius: 8px;
}
.banner-upload-image .add-image-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background: rgba(18, 23, 21, 0.05);
    border-radius: 8px;
    border: none;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    margin-bottom: 5px;
    color: #372D21;
    background-color: rgb(239, 239, 239);
}
.banner-upload-image .description{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #000000;
    opacity: 0.5;
}


.banner-preview-container {
    display: inline;
    position: relative;
    width: 320px;
    height: 160px;
}
.banner-preview-container .banner-preview-img{
    width: 320px;
    height: 160px;
    border-radius: 8px;
}
.banner-preview-container .banner-preview-close {
    position: absolute;
    right: 3px;
    top: 3px;
    padding: 1px;
    color: #FFFFFF;
    cursor: pointer;
    background: rgb(0,0,0,0.5);
    border-radius: 50%;
}