.product-details-style {
  width: 530px;
  height: 827px;
  position: relative;
}
.product-details-style .MuiFormLabel-root {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.15px;
}
.product-details-header-item {
  padding: 20px 25px 20px 25px;
  height: 74px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dcdcdc;
}
.product-details-header-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000000;
}
.product-details-body {
  display: flex;
  flex-direction: column;
  padding: 15px 30px 30px 30px;
  height: 689px;
  overflow-y: auto;
}
.product-details-row {
  display: flex;
  margin-top: 20px;
}
.product-details-row-action {
  border-top: 1px solid #dcdcdc;
  padding: 18px 25px 18px 25px;
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: white;
}
.product-details-row-action-btns {
  display: flex;
}
.product-details-spacer {
  width: 22px;
}

.product-details-small-input {
  width: 101px;
}
.product-details-description-text.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0px;
}
.product-details-description-text .MuiFormControlLabel-label,
.MuiTypography-body1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
}

.recommended-section {
  display: flex;
  justify-content: space-between;
  background: #f7f7f7;
  border-radius: 4px;
  flex: 1;
  margin-left: 8px;
}
.recommended-section .recommended-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
  display: flex;
  padding: 0px 15px 0px 15px;
  align-items: center;
}

.no-product-slected {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #000000;
  opacity: 0.5;
}

.menu-item-unit {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
}
.product-details-style .MuiButton-root {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  padding-left: 14px;
  padding-right: 14px;
}

.resize-image .MuiTypography-h6 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.5px;
  color: #000000;
  text-transform: capitalize;
}
.resize-image .MuiDialogContent-dividers {
  padding: 0px !important;
}

.resize-image .MuiButton-root {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  padding-left: 14px;
  padding-right: 14px;
}

.upload-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 136px;
  border: 1px dashed rgba(18, 23, 21, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
  flex: 1;
}
.upload-image .add-image-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 20px;
  background: rgba(18, 23, 21, 0.05);
  border-radius: 8px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 5px;
  color: #372d21;
  background-color: rgb(239, 239, 239);
}
.upload-image .description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #000000;
  opacity: 0.5;
}

.main-upload-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border: 1px dashed rgba(18, 23, 21, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
}
.main-upload-image .add-main-image-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 8px;
  margin-bottom: 8px;
  color: #000000;
  opacity: 0.5;
  background-color: rgb(239, 239, 239);
}
.main-upload-image .main-description {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #000000;
}

.image_info_banner {
  display: flex;
  height: 68px;
  width: 100%;
  background: #f7f7f7;
  border-radius: 4px;
  justify-content: space-around;
  align-items: center;
}
.image_info_banner .block_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image_info_banner .primary {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #000000;
  opacity: 0.4;
}
.image_info_banner .secondary {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #000000;
}

.main-preview-container {
  display: inline;
  position: relative;
}
.main-preview-container .main-preview-img {
  width: 140px;
  height: 140px;
  border-radius: 8px;
}
.main-preview-container .main-preview-close {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 1px;
  color: #ffffff;
  cursor: pointer;
  background: rgb(0, 0, 0, 0.5);
  border-radius: 50%;
}

.secondary-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}
.secondary-preview-container {
  display: inline;
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
}
.secondary-preview-container .secondary-preview-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}
.secondary-preview-container .secondary-preview-close {
  position: absolute;
  right: 1px;
  top: 1px;
  padding: 1px;
  color: #ffffff;
  cursor: pointer;
  background: rgb(0, 0, 0, 0.5);
  border-radius: 50%;
}

.secondary-small-upload-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border: 1px dashed rgba(18, 23, 21, 0.25);
  box-sizing: border-box;
  border-radius: 8px;
}
.secondary-small-upload-image .MuiSvgIcon-root {
  color: #000000;
  opacity: 0.5;
}
.secondary-small-upload-image .add-secondary-description {
  margin-top: 3px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #179f57;
}

.MuiAlert-root {
  border-radius: 8px !important;
}
.MuiAlert-filledSuccess {
  background-color: #f0fff1 !important;
  padding: 15px !important;
}
.MuiAlert-filledSuccess .MuiSvgIcon-root {
  fill: #179f57;
  height: 24px;
  width: 24px;
}
.MuiAlert-action {
  align-items: flex-start !important;
}
.MuiSvgIcon-fontSizeSmall {
  fill: #617367 !important;
  height: 16px !important;
  width: 16px !important;
}
.success-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #012910;
}
.success-secondary {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #617367;
}
