.select-all-order{
    display: flex;
    justify-content: center;
}
.select-all-order .action-btns-container{
    display: flex;
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    position: fixed;
    bottom: 15px;
}

.select-all-order .select-all-btn{
   margin: 10px 12px 10px 12px;
   font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-transform: none;
    color: #179F57;
}
.select-all-order .mark-deliverd-btn{
    margin: 10px 12px 10px 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-transform: none;
    color: #FFFFFF;
 }

.select-all-order .out-for-delivery-btn{
    margin: 10px 12px 10px 6px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-transform: none;
    color: #FFFFFF;
}