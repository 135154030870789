.community-list-style{
    width: 320px;
    height: 827px;
    display: flex;
    flex-direction: column;
}

.community-header-item{
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 66px;
}
.community-header-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #000000;
}


.community-list-body{
    overflow-y: auto;
}

.community-item{
    padding: 20px 25px 20px 25px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid  #DCDCDC;
    justify-content: space-between;
    cursor: pointer;
}
.community-name{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #000000;
    display: flex;
    flex: 1;
    word-break: break-word;
}
.community-delivery{
    margin-top: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #121715;
    opacity: 0.5;
    display: flex;
    flex: 1;
    word-break: break-word;
}