.sidenav {
    height: 100%;
    width: 120px;
    background-color: #179F57;;
    overflow-x: hidden;
    padding: 30px 0px 50px 0px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .sidenav .botiga_logo{
    width : 72px;
    height : 67px;
    margin-bottom: 55px;
  }



  .menu-icon-items{
    width : 80px;
    height : 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
    text-decoration: none;
  }
  .menu-icon-items .image-icon{
    width : 32px;
    height : 32px;
  }
  .menu-icon-items .text-icon{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    text-transform: uppercase;
    color: #FFFFFF;
  }

.active{
    background-color: seagreen;
    border-radius: 10px;
}

.logout_menu_item{
    position: fixed;
    bottom: 0;
}