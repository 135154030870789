.product-list-style {
  position: relative;
  width: 320px;
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  height: 827px;
  display: flex;
  flex-direction: column;
}
.product-item {
  padding: 8px 24px 20px 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dcdcdc;
  cursor: pointer;
}

.product-list-body {
  overflow-y: auto;
}

.product-header-item {
  padding: 20px 25px 20px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.product-header-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000000;
}
.product-header-btn.MuiButton-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #179f57;
}

.product-item-row-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.product-item-row-header .recomendation-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #ffffff;
  background: #ee5d0c;
  border-radius: 2px;
  padding: 4px;
  margin-top: 4px;
  margin-bottom: 3px;
}
.product-item-row-description {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  display: flex;
  word-break: break-word;
}
.product-status-text .MuiFormControlLabel-label {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: rgba(18, 23, 21, 0.5);
}
.product-secondary-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-right: 8px;
}
.product-secondary-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.text-light {
  color: rgba(18, 23, 21, 0.5);
}
.text-dark {
  color: rgba(18, 23, 21);
}
.price-info {
  display: flex;
  word-break: break-all;
}
.description {
  margin-top: 5px;
  display: flex;
  word-break: break-word;
}

.product-item-image-container {
  position: relative;
  max-height: 80px;
  max-width: 80px;
  min-width: 80px;
  min-height: 20px;
}
.product-item-image {
  height: 80px;
  width: 80px;
  border-radius: 4px;
}
.badge {
  position: absolute;
  top: -10px;
  left: -5px;
  background-color: #ffd953;
  align-items: center;
  padding: 4px;
  border-radius: 2px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #000000;
}
